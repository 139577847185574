<template>
  <v-container class="justify-center text-center align-center d-flex">
    <v-card width="80%" class="height-size">
      <v-sheet tile height="54" class="d-flex">
        <v-select
          v-model="type"
          :items="types"
          item-text="label"
          item-value="value"
          dense
          outlined
          hide-details
          class="ma-2"
          label="Modelo"
        ></v-select>
        <v-spacer></v-spacer>

        <NovoCompromisso :typeEvento="typeEvento" :processoDoc="processoDoc" />

        <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
          <v-icon small>fas fa-chevron-left</v-icon>
        </v-btn>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()">
          <v-icon small> fas fa-chevron-right </v-icon>
        </v-btn>
        <v-card-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}</v-card-title
        >
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          locale="pt-BR"
          ref="calendar"
          color="primary"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          :checkChange="true"
          @change="getEvents"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <CardAgenda :event="selectedEvent" />
        </v-menu>
      </v-sheet>
    </v-card>
  </v-container>
</template>

<script>
import NovoCompromisso from "@/components/Agenda/NovoCompromisso.vue";
import CardAgenda from "@/components/Agenda/CardAgenda.vue";
import moment from "moment";
import instanceAxios from "../../plugins/axios";
export default {
  components: {
    NovoCompromisso,
    CardAgenda,
  },

  props: {
    typeEvento: {
      type: String,
      required: true,
      default: "compromisso",
    },
    processoDoc: String,
  },

  data() {
    return {
      value: "",
      types: [
        { value: "month", label: "Mês" },
        { value: "week", label: "Semana" },
        { value: "day", label: "Dia" },
      ],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      type: "day",
      events: [],
      mode: "stack",
      selectedElement: null,
      selectedOpen: false,
      selectedEvent: {},
      oldDateStart: null,
      oldDateEnd: null,
    };
  },

  mounted() {
    this.$root.$on("updateAgenda", () =>
      this.getEvents({ start: this.oldDateStart, end: this.oldDateEnd })
    );
  },

  methods: {
    async getEvents({ start, end }) {
      this.oldDateStart = start;

      this.oldDateEnd = end;
      const { data } = await instanceAxios.get(
        `schedules?start=${start.date}&end=${end.date}`
      );

      this.events = data.map((element) => {
        return {
          name: element.schedule_type.name,
          start: moment(element.start_date).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(element.end_date).format("YYYY-MM-DD HH:mm:ss"),
          timed: true,
          color: "green",
          details: element.description,
          ...element,
        };
      });

      console.log(this.events);
    },
    getEventColor(event) {
      console.log("evento ", event);
      if (!event || !event.schedule_status) {
        return "red";
      }
      let finalizado = event.schedule_status.find(
        (el) => el.name === "Realizado"
      );

      if (!finalizado && moment(event.start_date).isBefore()) {
        return "red";
      }

      return "green";
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
  },
};
</script>

<style></style>
