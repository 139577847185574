<template>
  <v-card color="grey lighten-4" min-width="350px" flat>
    <v-toolbar :color="getEventColor(event)" dark>
      <!-- <v-btn icon @click="editEventModal">
                <v-icon>fas fa-edit</v-icon>
              </v-btn> -->
      <v-toolbar-title>
        {{ event.name }}
        {{ event.start_date | formatDate }} - {{ event.end_date | formatHour }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <AgendaTrash :event="event" />

      <PreviewAgenda :event="event" />
    </v-toolbar>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <strong>
              Descrição
            </strong>

            {{ event.description }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <strong>
              Complemento
            </strong>
            <span>
              {{ event.complement || "Sem complemento" }}
            </span>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <strong>
              Observação
            </strong>
            <span>
              {{ event.observation || "Sem observação" }}
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="secondary" @click="selectedOpen = false">
        Cancelar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import AgendaTrash from "@/components/Agenda/AgendaTrash.vue";
import PreviewAgenda from "@/components/Agenda/PreviewAgenda.vue";

export default {
  components: {
    AgendaTrash,
    PreviewAgenda,
  },

  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY HH:mm");
    },

    formatHour: function(el) {
      return moment(el).format("HH:mm");
    },
  },

  data() {
    return {
      dialogTrash: false,
      dialogShow: false,
      selectedOpen: false,
    };
  },

  methods: {
    getEventColor(event) {
      console.log("evento ", event);
      if (!event || !event.schedule_status) {
        return "red";
      }
      let finalizado = event.schedule_status.find(
        (el) => el.name === "Realizado"
      );

      if (!finalizado && moment(event.start_date).isBefore()) {
        return "red";
      }

      return "green";
    },
  },
};
</script>

<style></style>
