<template>
  <v-dialog width="600" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab icon v-bind="attrs" v-on="on" @click="dialog = true">
        <v-icon>fas fa-trash</v-icon>
      </v-btn>
    </template>
    <v-card class="text-center">
      <v-card-title>
        Você tem certeza que deseja excluir este compromisso?
      </v-card-title>
      <v-card-text>
        {{ event.schedule_type.name }} de
        {{ event.start_date | formatDate }} ate
        {{ event.end_date | formatDate }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex text-center">
        <v-spacer></v-spacer>
        <v-btn color="error" @click="excluirEvento"> Confirmar </v-btn>
        <v-btn color="primary" @click="dialog = false">
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import instanceAxios from "../../plugins/axios";
import ToastService from '../../services/ToastService';
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY HH:mm");
    },

    formatHour: function(el) {
      return moment(el).format("HH:mm");
    },
  },

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    async excluirEvento() {
      await instanceAxios
        .delete(`schedules/${this.event.id}`)
        .then(() => {
          ToastService("Compromisso cancelado com sucesso", "success");
          this.dialog = false;
          this.$root.$emit('updateAgenda')
        })
        .catch((error) => {
          console.log(error);
          ToastService("Não foi possivel cancelar este compromisso", "error");
        });
    },
  },
};
</script>

<style></style>
