<template>
  <div class="text-center">
    <v-dialog width="500" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab icon v-bind="attrs" v-on="on" @click="dialog = true">
          <v-icon>fas fa-eye</v-icon>
        </v-btn>
      </template>
      <v-card class="pl-4 pr-4 pb-4 text-center">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> Tipo </v-list-item-title>
            <v-list-item-subtitle>
              {{ event.schedule_type.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title> Horario </v-list-item-title>
            <v-list-item-subtitle>
              {{ event.start_date | formatDate }} -
              {{ event.end_date | formatHour }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title> Solicitantes </v-list-item-title>
            <v-list-item-subtitle
              v-for="user in event.users"
              :key="'solicitante' + user.id"
              v-show="user.pivot.type == 'requester'"
            >
              {{ user.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title> Responsaveis </v-list-item-title>
            <v-list-item-subtitle
              v-for="user in event.users"
              v-show="user.pivot.type == 'responsible'"
              :key="'responsavel' + user.id"
            >
              {{ user.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title> Status </v-list-item-title>

            <v-list-item-subtitle
              v-for="status in event.schedule_status"
              :key="'status' + status.id"
            >
              {{ status.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title> Descrição </v-list-item-title>
            <v-list-item-subtitle>
              {{ event.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title> Complemento </v-list-item-title>
            <v-list-item-subtitle>
              {{ event.complement }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title> Observação </v-list-item-title>
            <v-list-item-subtitle>
              {{ event.observation }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  data() {
      return {
          dialog: false,
      }
  },

  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY HH:mm");
    },

    formatHour: function(el) {
      return moment(el).format("HH:mm");
    },
  },
};
</script>

<style></style>
