<template>
  <div>
    <AgendaShow :typeEvento="typeEvento" :processoDoc="processoDoc" />
  </div>
</template>

<script>
import AgendaShow from "../../components/Agenda/AgendaShow.vue";
export default {
  components: {
    AgendaShow,
  },

  props: {
    typeEvento: {
      type: String,
      required: false,
      default: 'compromisso'
    },
    processoDoc: String,
  },
};
</script>
